import React from 'react'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'
import * as R from 'fp-ts/Record'
import { pipe } from 'fp-ts/pipeable'

import * as styleRefs from './Text.treat'

const variants = {
  'sans-38-72': {
    fontFamily: 'sans',
  },
  'sans-38-52': {
    fontFamily: 'sans',
  },
  'sans-30-56': {
    fontFamily: 'sans',
  },
  'sans-30-52': {
    fontFamily: 'sans',
  },
  'sans-32-44': {
    fontFamily: 'sans',
  },
  'sans-26': {
    fontFamily: 'sans',
  },
  'sans-20-32': {
    fontFamily: 'sans',
  },
  'sans-20': {
    fontFamily: 'sans',
  },
  'sans-18-28': {
    fontFamily: 'sans',
  },
  'sans-16-22': {
    fontFamily: 'sans',
  },
  'sans-16-24': {
    fontFamily: 'sans',
  },
  'sans-16': {
    fontFamily: 'sans',
  },
  'sans-16-18': {
    fontFamily: 'sans',
  },
  'sans-14-16': {
    fontFamily: 'sans',
  },
  'sans-13-18': {
    fontFamily: 'sans',
  },
  'sans-13-20': {
    fontFamily: 'sans',
  },
  'sans-12': {
    fontFamily: 'sans',
  },

  'sansCond-16-17': {
    fontFamily: 'sansCond',
  },
  'sansCond-14': {
    fontFamily: 'sansCond',
  },

  'serif-24-40': {
    fontFamily: 'serif',
  },
  'serif-22-40': {
    fontFamily: 'serif',
  },
  'serif-22-48': {
    fontFamily: 'serif',
  },
  'serif-22-32': {
    fontFamily: 'serif',
  },
} as const

const styledVariants = {
  'sans-light-30-56': {
    textVariant: 'sans-30-56',
    styles: { fontWeight: 'light' },
  },
  'sans-light-38-72': {
    textVariant: 'sans-38-72',
    styles: { fontWeight: 'light' },
  },
  'sans-light-20-32': {
    textVariant: 'sans-20-32',
    styles: { fontWeight: 'light' },
  },
  'sans-medium-18-28': {
    textVariant: 'sans-18-28',
    styles: { fontWeight: 'medium' },
  },
  'sans-caps-semibold-20': {
    textVariant: 'sans-20',
    styles: {
      fontWeight: 'semibold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },
  'sans-caps-bold-14-16': {
    textVariant: 'sans-14-16',
    styles: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },
  'sans-caps-bold-13-18': {
    textVariant: 'sans-13-18',
    styles: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },
  'sans-caps-bold-13-20': {
    textVariant: 'sans-13-20',
    styles: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },
  'sans-caps-semibold-13-18': {
    textVariant: 'sans-13-18',
    styles: {
      fontWeight: 'semibold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },
  'sans-caps-semibold-13-20': {
    textVariant: 'sans-13-20',
    styles: {
      fontWeight: 'semibold',
      textTransform: 'uppercase',
      letterSpacing: 'm',
    },
  },

  'serif-medium-italic-24-40': {
    textVariant: 'serif-24-40',
    styles: {
      fontWeight: 'medium',
      fontStyle: 'italic',
    },
  },
  'serif-medium-italic-22-40': {
    textVariant: 'serif-22-40',
    styles: {
      fontWeight: 'medium',
      fontStyle: 'italic',
    },
  },
  'serif-medium-italic-22-32': {
    textVariant: 'serif-22-32',
    styles: {
      fontWeight: 'medium',
      fontStyle: 'italic',
    },
  },
} as const

const normalizedVariants = {
  ...pipe(
    variants,
    R.mapWithIndex((textVariant, styles) => ({ textVariant, styles })),
  ),
  ...pipe(
    styledVariants,
    R.map((variant) => ({
      ...variant,
      styles: {
        ...variants[variant.textVariant],
        ...variant.styles,
      },
    })),
  ),
}

export type TextProps = {
  variant: keyof typeof normalizedVariants
} & BoxProps

export const Text = ({
  variant: variantName,
  className,
  ...props
}: TextProps) => {
  const styles = useStyles(styleRefs)
  const variant = normalizedVariants[variantName]

  return (
    <Box
      className={clsx(styles.variants[variant.textVariant], className)}
      {...props}
      styles={{
        ...variant.styles,
        ...props.styles,
      }}
    />
  )
}
