import React from 'react'
import { Dialog } from '@headlessui/react'

import * as styles from './Modal.module.css'

export interface ModalProps {
  isOpen: boolean
  closeModal: () => void
  children?: React.ReactNode
  className?: string
}

export let Modal = ({ isOpen, closeModal, children }: ModalProps) => {
  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <Dialog.Overlay className={styles.overlay} />

      {children}
    </Dialog>
  )
}
