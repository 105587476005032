import React from 'react'

export let Close = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg viewBox="0 0 21.41 21.41" {...props}>
      <g
        fill="none"
        stroke="currentColor"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path d="M.71.71l20 20M20.71.71l-20 20"></path>
      </g>
    </svg>
  )
}
