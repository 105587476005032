import React from 'react'

import { useSpeedbump } from '../hooks/useSpeedbump'
import { Modal } from './Modal'
import { Close } from '../lib/Close'
import { Text } from './Text'
import { Button } from './Button'

import * as styles from './SpeedbumpModal.module.css'

export let SpeedBumpModal = () => {
  let { speedbump, closeSpeedbump } = useSpeedbump()

  return (
    <Modal isOpen={speedbump.isOpen} closeModal={closeSpeedbump}>
      <div className={styles.containerStyles}>
        <button onClick={closeSpeedbump} className={styles.closeButton}>
          <Close className={styles.closeIcon} />
        </button>

        <div className={styles.headings}>
          <Text
            component="h3"
            variant="sans-32-44"
            styles={{ fontWeight: 'light', color: 'beige40' }}
          >
            Attention
          </Text>

          <Text
            component="h4"
            variant="sans-caps-bold-14-16"
            styles={{ color: 'maroon50', marginTop: 3.5 }}
          >
            You are leaving HawaiiNational.Bank
          </Text>
        </div>

        <Text component="p" variant="sans-16" styles={{ maxWidth: '70ch' }}>
          By clicking "OK" you acknowledge you are leaving our website. While we
          select companies we do business with because of their high level of
          quality, our online Privacy Policy does not apply to third-party
          sites; therefore, we encourage you to read the security/privacy
          information for each site you visit.
        </Text>

        <div className={styles.buttons}>
          <Button
            variant="red"
            onClick={closeSpeedbump}
            styles={{ marginRight: 3 }}
          >
            Cancel
          </Button>

          <Button
            component="a"
            variant="red"
            href={speedbump.destinationLink}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  )
}
