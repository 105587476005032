import { atom, useAtom } from 'jotai'

interface SpeedbumpAtom {
  isOpen: boolean
  destinationLink?: string
}

let speedbumpAtom = atom<SpeedbumpAtom>({
  isOpen: false,
  destinationLink: undefined,
})

export function useSpeedbump() {
  let [speedbump, setSpeedbump] = useAtom(speedbumpAtom)

  function openSpeedbump(destinationLink: string) {
    setSpeedbump({ isOpen: true, destinationLink })
  }

  function closeSpeedbump() {
    setSpeedbump({ isOpen: false, destinationLink: undefined })
  }

  return { speedbump, openSpeedbump, closeSpeedbump }
}
